<script setup lang="ts">
import { useAsyncData } from 'nuxt/app';

import useConfig from '../composables/useConfig.ts';
import { fetchJson } from '../src/fetchJson.ts';

const props = withDefaults(defineProps<{
    type: string;
    item: string;
    colour?: string;
}>(), {
    colour: 'light',
});

const config = useConfig();

const edition = config.census.enabled ? config.census.latestResults : undefined;

const { data: percentage } = useAsyncData(async () => {
    if (!edition) {
        return null;
    }
    try {
        return await fetchJson(
            `${edition}/general/stats.json`,
            `${props.type}.${props.item}`,
        );
    } catch (error) {
        console.error(error);
        return null;
    }
});
</script>

<template>
    <div v-if="edition && percentage !== null" :class="`alert alert-${colour}`">
        <Icon v="user-chart" />
        <T :params="{ item, percentage: `${percentage}%` }">census.stats.{{ type }}</T>
        <br>
        <small><T :params="{ edition, year: edition!.slice(-4) }">census.stats.source</T></small>
    </div>
</template>
